<template>
    <div
        class="fixed z-40 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full	 bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
            'opacity-0 invisible pointer-events-none scale-75': !isVisible,
        }"
    >

        <div class="px-5 py-5 bg-white rounded-lg  w-128">
            <div
                class="modal-header flex flex-shrink-0 pb-1 items-center justify-between border-b border-gray-200 rounded-t-md">
                <h5 class="text-xl font-medium leading-normal text-gray-800">
                    {{ formData.id ? 'Edit' : 'Add ' }} Event
                </h5>

                <svg @click="cancelled" class="w-6 h-6 cursor-pointer stroke-gray-400 hover:stroke-red-500"
                     fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </div>
            <div>
                <div class="py-5 bg-white">
                    <div class="grid grid-cols-6 gap-4 text-left">
                        <div class="col-span-6">
                            <label for="title" class="block text-md font-medium text-gray-700">Title</label>
                            <app-input id="title" v-model:value="formData.title"/>
                            <span class="text-red-500" v-if="errors && errors['title']">{{ errors['title'][0] }}</span>
                            <!--                            <input type="text" name="street_address" id="street_address" autocomplete="street-address" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">-->
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                            <label for="start_date" class="block text-sm font-medium text-gray-700">Start Date</label>
                            <!--                            <input type="date" name="first_name" id="first_name" autocomplete="given-name">-->
                            <Datepicker teleportCenter v-model="formData.start_date" format="dd/MM/yyyy HH:mm" id="start_date"/>
                            <span class="text-red-500"
                                  v-if="errors && errors['start_date']">{{ errors['start_date'][0] }}</span>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <label for="end_date" class="block text-sm font-medium text-gray-700">End Date</label>
                            <Datepicker teleportCenter v-model="formData.end_date" format="dd/MM/yyyy HH:mm" id="end_date"/>
                            <span class="text-red-500"
                                  v-if="errors && errors['end_date']">{{ errors['end_date'][0] }}</span>
                        </div>

                        <div class="col-span-6">
                            <label for="event_details" class="block text-sm font-medium text-gray-700">Notes</label>
                            <appTextarea id="event_details" v-model:value="formData.notes"/>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <app-checkbox v-model:checked="formData.unavailable" label="Unavailable" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex gap-2 justify-center mt-2">
                <button
                    @click="storeData"
                    class="w-1/2 px-4 text-sm font-semibold text-white bg-green-500 border-2 border-green-500 shadow leading-6 rounded-md hover:bg-green-600 transition ease-in-out duration-150"
                >
                    {{ formData.id ? 'Update' : 'Create' }} Event
                </button>
                <button
                    @click="cancelled"
                    class="w-1/2 px-4 text-sm font-semibold text-white bg-blue-500 border-2 border-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import AppInput from "@/components/AppInput";
import AppCheckbox from '@/components/AppCheckbox';
import appTextarea from "@/components/AppTextarea";
import JetInput from "@/components/JetInput";
import {createEvent, updateEvent} from "@/services/CalendarEventService";
import {getLocalISOTime} from "@/utils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const form = {
    id: null,
    shortlisted_speaker_id: null,
    title: null,
    start_date: null,
    end_date: null,
    notes: null,
    unit: null,
    interval: null,
    until: null,
    speaker_id: null,
    event_status: null,
    unavailable: null
}
export default {
    name: "AddEvent",
    components: {
        AppInput,
        appTextarea,
        Datepicker,
        JetInput,
        AppCheckbox
    },
    data() {
        return {
            formData: {...form},
            errors: []
        }
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: null
        },

    },
    watch: {
        'event': function (val) {
            if (val) {
                this.formData = {...val}
            }
        },
        'formData.unavailable': function (val) {
            if (val === true)
            {
                this.unavailableTicked();
            }
        }
    },
    methods: {
        cancelled() {
            this.$emit('update:isVisible', false)
        },
        storeData() {
        
            this.formData.start_date = getLocalISOTime(this.formData.start_date)
            this.formData.end_date = getLocalISOTime(this.formData.end_date)
            if (this.formData.id) {
                updateEvent(this.formData.id, this.formData).then((res) => {
                    this.storeSuccess(res.data)
                }).catch(err => {
                    if (err.response.data.errors) {
                        this.showErrors(err.response.data.errors)
                    }
                })
            } else {
                this.formData.event_status = "Manual"; 
                createEvent(this.formData).then((res) => {
                    this.storeSuccess(res.data)
                }).catch(err => {
                    if (err.response.data.errors) {
                        this.showErrors(err.response.data.errors)
                    }
                })
            }
        },

        showErrors(err) {
            this.errors = err
            setTimeout(() => {
                this.errors = []
            }, 3000)
        },

        storeSuccess(data) {
            this.formData = {...form}
            this.$emit('eventCreated')
            this.cancelled()
        },

        unavailableTicked() {
            // Append unavailable to description if it isn't already there.
            if (this.formData.notes == null)
            {
                this.formData.notes = '** Unavailable **';
            }
            else if (!this.formData.notes.endsWith('** Unavailable **'))
            {
                this.formData.notes += ' ** Unavailable **';
            }
        }
    }
}
</script>

<style scoped>

</style>