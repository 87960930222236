<template>
  <!-- component -->
  <!-- This is an example component -->
  <div class="relative mx-4 mt-4">
    <div class="flex">
      <div class="basis-1/4">
        <div class="flex gap-x-8">
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded text-center whitespace-nowrap"
            @click.prevent="processGenerateInvoice"
            v-show="isAnySpeakerBooked"
          >
            Generate Invoices
          </button>
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded text-center whitespace-nowrap"
            @click.prevent="processGeneratePO"
            v-show="isAnySpeakerBooked"
          >
            Generate PO
          </button>
        </div>
      </div>
      <div class="basis-1/4"></div>
      <div class="basis-1/2">
        <div class="grid justify-items-end">
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            @click.prevent="openFilterShortlistedSpeakers = true"
          >
            Search Speakers
          </button>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="flex mt-4">
        <table
          class="table-auto w-72 overflow-x-auto border-2 text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            id="shortlisted-speakers-table-head"
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3 text-center">No</th>
              <th scope="col" class="text-center px-6 py-3">Action</th>
              <th scope="col" class="px-6 py-3 text-center sticky left-0 bg-gray-50 dark:bg-gray-700">Speaker Name</th>
              <th scope="col" class="px-6 py-3 text-center">Shortlist</th>
              <th scope="col" class="px-6 py-3 text-center w-40">Status</th>
              <th scope="col" class="px-6 py-3 text-center"></th>
              <th scope="col" class="px-6 py-3 text-center">Commission</th>
              <th scope="col" class="px-6 py-3 text-center">Exclusivity</th>
              <th scope="col" class="px-6 py-3 text-center">Live Keynote Fee</th>
              <th scope="col" class="px-6 py-3">In Conversation Fee</th>
              <th scope="col" class="px-6 py-3">Virtual/Pre-recorded Fee</th>
              <th scope="col" class="px-6 py-3">Full Day Workshop Fee</th>
              <th scope="col" class="px-6 py-3">Half Day Workshop Fee</th>
              <th scope="col" class="px-6 py-3">MC Half Day Fee</th>
              <th scope="col" class="px-6 py-3">MC Full Day Fee</th>
              <th scope="col" class="px-6 py-3">Education/NFP Fee</th>
              <th scope="col" class="px-6 py-3">Campaign Fee</th>
              <th scope="col" class="px-6 py-3">Other Fee</th>
              <th scope="col" class="px-6 py-3">Account Name</th>
              <th scope="col" class="px-6 py-3">Contact Name</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="shortlistedSpeakerList.length > 0">
              <tr
                v-for="(speaker, index) in shortlistedSpeakerList"
                :key="index"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td class="px-6 py-4">
                  {{ index + 1 }}
                </td>
                <td class="px-6 py-4">
                  <div class="flex gap-x-4">
                    <div class="flex-auto py-1">
                      <a
                        :href="
                          shortlisted_speaker_crm_url +
                          speaker.shortlisted_speaker_crm_id +
                          '/edit'
                        "
                        target="_blank"
                        v-show="speaker.shortlisted_speaker_crm_id"
                      >
                        <!-- <font-awesome-icon icon="fa-solid fa-pen" /> -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                        >
                          <path
                            d="M14.5 5.5L3 17 3 21 7 21 18.5 9.5zM21.2 2.8c-1.1-1.1-2.9-1.1-4 0L16 4l4 4 1.2-1.2C22.3 5.7 22.3 3.9 21.2 2.8z"
                          />
                        </svg>
                      </a>
                    </div>
                    <div class="flex-auto">
                      <button
                        :class="[
                          'px-4 py-1 text-gray-700 ring-1 ring-inset ring-gray-700 rounded-md',
                          speaker.file_generated == true
                            ? 'bg-green-500'
                            : 'bg-white',
                        ]"
                        @click="generateFile(speaker)"
                        v-if="speaker.status == 'Booked'"
                      >
                        {{
                          speaker.file_generated == true
                            ? "Generated"
                            : "Generate"
                        }}
                      </button>
                    </div>
                  </div>
                </td>
                <th
                  scope="row"
                  class="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap sticky left-0 bg-inherit z-1"
                >
                  <!-- <a :href="'https://crm.zoho.com/crm/org749100371/tab/CustomModule4/' +speaker.speaker.crm_id"
                        class="text-blue-500"
                        target="_blank"
                        @mouseover="onHover(speaker.speaker.crm_id)"
                        >{{ speaker.speaker.speaker_name }}
                      </a> -->
                  <el-popover
                    placement="right"
                    :width="500"
                    trigger="hover"
                    :show-after="500"
                  >
                    <template #reference>
                      <a
                        :href="
                          speaker_crm_url +
                          speaker.speaker.crm_id
                        "
                        class="text-blue-500"
                        target="_blank"
                        @mouseover="onHover(speaker.speaker.crm_id)"
                        >{{ speaker.speaker.speaker_name }}
                      </a>
                    </template>
                    <el-descriptions
                      :title="speaker.speaker.speaker_name"
                      :column="1"
                      :size="size"
                      direction="horizontal"
                      :style="blockMargin"
                    >
                      <el-descriptions-item label="Email">{{
                        speakerFeeData.Email
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Agent/Management Company">{{
                        speakerFeeData.Agent_Management_Company
                      }}</el-descriptions-item>   
                      <el-descriptions-item label="Live Keynote Fee">{{
                        speakerFeeData.Live_Keynote_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="In Conversation Fee">{{
                        speakerFeeData.In_Conversation_Fee
                     }}</el-descriptions-item>
                      <el-descriptions-item label="Virtual Keynote Fee">{{
                        speakerFeeData.Virtual_Keynote_Livestream_Fee
                     }}</el-descriptions-item>
                      <el-descriptions-item label="Full Day Workshop Fee">{{
                        speakerFeeData.Live_Workshop_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Half Day Workshop Fee">{{
                        speakerFeeData.Half_Day_Workshop_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="MC Half Day Fee">{{
                        speakerFeeData.MC_Rate
                      }}</el-descriptions-item>
                      <el-descriptions-item label="MC Full Day Fee">{{
                        speakerFeeData.MC_Full_Day
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Campaign Fee">{{
                        speakerFeeData.Campaign_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Other Fee">{{
                        speakerFeeData.Other_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Education/NFP Fee">{{
                        speakerFeeData.Education_NFP_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Gross Fees Range">{{
                        speakerFeeData.Gross_Fees_Range
                      }}</el-descriptions-item>
                      <el-descriptions-item label="% Commission">{{
                        speakerFeeData.Gross_Fees_for_Dues
                      }}</el-descriptions-item>
                      <el-descriptions-item label="City">{{
                        speakerFeeData.City
                      }}</el-descriptions-item>                                       
                    </el-descriptions>
                  </el-popover>
                </th>
                <th
                  scope="row"
                  class="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  <a
                    :href="
                      shortlisted_speaker_crm_url +
                      speaker.shortlisted_speaker_crm_id
                    "
                    class="text-blue-500"
                    target="_blank"
                    v-show="speaker.shortlisted_speaker_name"
                    >{{ speaker.shortlisted_speaker_name }}
                  </a>
                </th>
                <td class="px-6 py-4">
                  <div class="relative text-center whitespace-nowrap w-32">
                    <select
                      @change="(event) => selectOption(event, speaker)"
                      :class="[
                        'block appearance-none w-full border border-gray-200 text-gray-700 py-1 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
                        'status-' + speaker.speaker.crm_id,
                      ]"
                      id="grid-state"
                    >
                      <option
                        v-for="(option, index) in statusOptions"
                        :selected="
                          speaker.status == option.label ? 'selected' : null
                        "
                        :value="option.id"
                        :key="index"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4">
                  <span :class="changeColor(speaker.status)">
                    {{ speaker.status }}
                  </span>
                </td>

                <td class="px-6 py-4">
                  <div
                    class="flex gap-x-2"
                    v-if="speaker.shortlisted_speaker_crm_id"
                  >
                    <div class="flex-auto w-28">
                      <jet-input
                        :style="`px-1 py-2 text-xs`"
                        @keyup="getCommission"
                        :id="'input-' + speaker.shortlisted_speaker_crm_id"
                        class="py-2 px-2 rounded"
                        :value="
                          getSSCommission(speaker.shortlisted_speaker_crm_id)
                        "
                      />
                     
                       
                    </div>
                    <div class="relative flex-auto w-28">
                      <select
                      @change="(event) => optionPicked(event, speaker)"
                      :class="[
                        'block appearance-none w-full border border-gray-200 text-gray-700 py-2.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
                      ]"
                      :id="'comtype-'+speaker.shortlisted_speaker_crm_id"
                    >
                      <option
                        v-for="(option, index) in commissionType"
                        :selected="
                          checkCom(speaker.shortlisted_speaker_crm_id,option.id) ? 'selected' : null
                        "
                        :value="option.id"
                        :key="index"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>

                      
                    </div>

                    <div
                      class="flex-auto py-2"
                      :class="[
                        'showTick-' + speaker.shortlisted_speaker_crm_id,
                      ]"
                      hidden
                    >
                      <a
                        @click="
                          updateCommission(speaker.shortlisted_speaker_crm_id)
                        "
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                        >
                          <path
                            d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"
                          />
                        </svg>
                        <!-- <font-awesome-icon icon="fa-solid fa-check"
                      /> -->
                      </a>
                    </div>
                  </div>
                </td>

                <td class="text-center px-6 py-4">
                  {{ speaker.speaker.exclusivity }}
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_LKF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Live_Keynote_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_LKF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_LKF_to_Qwilr')"         
                            :id="'Live_Keynote_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Live_Keynote_Fee")'
                          />
                          <!-- @blur="updateFeeAmount($event,speaker.shortlisted_speaker_crm_id,'Live_Keynote_Fee')" :disabled="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_LKF_to_Qwilr') == false" -->
                      </div>
                      <div :class="['py-1','Live_Keynote_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_LKF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Live_Keynote_Fee','Allow_LKF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                   
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_ICF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'In_Conversation_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_ICF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_ICF_to_Qwilr')"         
                            :id="'In_Conversation_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"In_Conversation_Fee")'
                          />
                      </div>
                      <div :class="['py-1','In_Conversation_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_ICF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'In_Conversation_Fee','Allow_ICF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                   
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_VKFL_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Virtual_Keynote_Fee_Livestream')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_VKFL_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_VKFL_to_Qwilr')"
                            :id="'Virtual_Keynote_Fee_Livestream-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Virtual_Keynote_Fee_Livestream")'
                          />
                      </div>
                      <div :class="['py-1','Virtual_Keynote_Fee_Livestream-'+ speaker.shortlisted_speaker_crm_id,'Allow_VKFL_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Virtual_Keynote_Fee_Livestream','Allow_VKFL_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_FDWF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Full_Day_Workshop_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_FDWF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_FDWF_to_Qwilr')"
                            :id="'Full_Day_Workshop_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Full_Day_Workshop_Fee")'
                          />
                      </div>
                      <div :class="['py-1','Full_Day_Workshop_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_FDWF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Full_Day_Workshop_Fee','Allow_FDWF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_HDWF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Half_Day_Workshop_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_HDWF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_HDWF_to_Qwilr')"
                            :id="'Half_Day_Workshop_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Half_Day_Workshop_Fee")'
                          />
                      </div>
                      <div :class="['py-1','Half_Day_Workshop_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_HDWF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Half_Day_Workshop_Fee','Allow_HDWF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_MCHDF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'MC_Half_Day_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_MCHDF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_MCHDF_to_Qwilr')"
                            :id="'MC_Half_Day_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"MC_Half_Day_Fee")'
                          />
                      </div>
                      <div :class="['py-1','MC_Half_Day_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_MCHDF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'MC_Half_Day_Fee','Allow_MCHDF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_MCFDF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'MC_Full_Day_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_MCFDF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_MCFDF_to_Qwilr')"
                            :id="'MC_Full_Day_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(speaker.shortlisted_speaker_crm_id,"MC_Full_Day_Fee")'
                          />
                      </div>
                      <div :class="['py-1','MC_Full_Day_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_MCFDF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'MC_Full_Day_Fee','Allow_MCFDF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_ENF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Education_NFP_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_ENF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_ENF_to_Qwilr')"
                            :id="'Education_NFP_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Education_NFP_Fee")'
                          />
                      </div>
                      <div :class="['py-1','Education_NFP_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_ENF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Education_NFP_Fee','Allow_ENF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_CF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Campaign_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_CF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_CF_to_Qwilr')"
                            :id="'Campaign_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Campaign_Fee")'
                          />
                      </div>
                      <div :class="['py-1','Campaign_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_CF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Campaign_Fee','Allow_CF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    <div class="flex flex-row gap-4">
                      <div class="py-3">
                        <input
                          :id="'Allow_OF_to_Qwilr-' + speaker.shortlisted_speaker_crm_id"
                          type="checkbox"
                          @change="keyNoteValueChangeHandle($event,'Other_Fee')"
                          :checked="getAllowedCheckboxVal(speaker.shortlisted_speaker_crm_id,'Allow_OF_to_Qwilr')"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <jet-input
                            :style="`px-1 py-2 text-xs`"
                            @keyup="keyNoteValueChangeHandle($event,'Allow_OF_to_Qwilr')"
                            :id="'Other_Fee-' + speaker.shortlisted_speaker_crm_id"
                            class="py-2 px-2 rounded w-20"
                            :value='getFeeAmount(
                            speaker.shortlisted_speaker_crm_id,"Other_Fee")'
                          />
                      </div>
                      <div :class="['py-1','Other_Fee-'+ speaker.shortlisted_speaker_crm_id,'Allow_OF_to_Qwilr-'+ speaker.shortlisted_speaker_crm_id]" hidden>
                        <button
                          @click="updateFeeAmount(speaker.shortlisted_speaker_crm_id,'Other_Fee','Allow_OF_to_Qwilr')"
                          class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  {{ speaker.speaker.account_name }}
                </td>
                <td class="text-center px-6 py-4">
                  {{ speaker.contact_name }}
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="9" class="text-center text-gray-700">
                No Data Available!
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- {{ shortlistedSpeakerList }} -->
    </div>
    <GenerateInvoice
      :dealInfo="dealInfo"
      :shortlistedSpeakerList="shortlistedSpeakerList"
      :shortListedSpeakersInfo="shortListedSpeakersInfo"
      :calculatedBill="calculatedBill"
      v-model:invoiceLoaded="invoiceLoaded"
      v-model:is-visible="openGenerateInvoice"
    />
    <GeneratePO
      :dealInfo="dealInfo"
      :shortListedSpeakersInfo="allShortlistedPOInfo"
      v-model:is-visible="openGeneratePO"
    />
    <FilterShortlistedSpeakers
      :dealInfo="dealInfo"
      :speakerList="speakerList"
      v-model:is-visible="openFilterShortlistedSpeakers"
    />
    <GenerateFile
      :dealInfo="dealInfo"
      v-model:is-visible="openGenerateFileModal"
    />
    <div
      v-if="showLoadingScreen"
      class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-slate-300"
    >
      <loading>{{ loadingText }}</loading>
    </div>
    <div
      v-if="loading"
      class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen"
    >
      <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
        {{ loadingText }}
      </loading>
    </div>
  </div>

  <div
    class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !showPopUpModal,
    }"
  >
    <div class="px-10 py-5 bg-white rounded-lg ring-2 w-auto">
      <div
        class="flex items-center text-blue-500 mt-2 mb-4 ml-2"
        style="white-space: pre"
      >
        {{ message }}
      </div>
      <div class="flex justify-center mt-2">
        <button
          @click="showPopUpModal = false"
          class="w-1/4 px-4 text-sm font-semibold text-white bg-gray-500 border-2 border-green-500 shadow leading-6 rounded-md transition ease-in-out duration-150"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
  <div
    class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !showHeldPopUpModal,
    }"
  >
    <div class="px-10 py-5 bg-white rounded-lg ring-2 w-100">
      <div class="grid grid-cols-2 gap-4 py-2">
        <div class="items-end text-blue-500 pt-2">
          {{ isBookedPopUp ? "Book Date Range:" : "Hold Date Range:" }}
        </div>
        <div class="items-center">
          <date-time-input
            v-model="eventDateTime"
            teleport-center
            format="dd/MM/yyyy HH:mm"
            range :partial-range="false"
          ></date-time-input>
        </div>     
      </div>
      <div v-for="(additionalDate, index) in additionalDates">
        <div class="grid grid-cols-2 gap-4 py-2" >
          
              <div class="items-end text-blue-500 pt-2">
                Additional Date Range:
              </div>
              <div class="items-center">
                <date-time-input
                  v-model="additionalDate.date_time"
                  teleport-center
                  format="dd/MM/yyyy HH:mm"
                  range :partial-range="false"
                ></date-time-input>
              </div>
        </div>
      </div>
      <div
        class="items-start text-red-500 mt-2 mb-4 ml-2 py-4"
        v-if="notDateFound"
      >
        <span>Please select your desire date range.</span>
      </div>
      <div class="flex justify-center mt-2 gap-4 py-4">
        <button
          @click="addMore"
          class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded text-center whitespace-nowrap"
        >
          Add More
        </button>
        <button
          @click="updateStatus"
          class="w-1/4 px-4 text-sm font-semibold text-white bg-blue-500 border-2 shadow leading-6 rounded-md transition ease-in-out duration-150"
        >
          Update
        </button>
        <button
          @click="cancelUpdateStatus"
          class="w-1/4 px-4 text-sm font-semibold text-white bg-red-500 border-2 shadow leading-6 rounded-md transition ease-in-out duration-150"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import AppLabel from "@/components/AppLabel";
import GenerateInvoice from "@/views/Shortlisted-Speakers/GenerateInvoice";
import GeneratePO from "@/views/Shortlisted-Speakers/GeneratePO";
import FilterShortlistedSpeakers from "@/views/Shortlisted-Speakers/FilterShortlistedSpeakers";
import {
  fetchShortlistedSpeakers,
  updateShortlistedSpeaker,
  generateFile,
  syncShortlistedSpeaker,
} from "@/services/ShortlistedSpeakerService";
import { getLocalISOTime } from "@/utils";
import JetInput from "@/components/JetInput";
import AppSelect from "@/components/AppSelect";
import Loading from "@/components/Loading";
import GenerateFile from "@/components/GenerateFile";
import DateTimeInput from "@/components/DateTimePicker.vue";
import { ElMessage, ElMessageBox } from 'element-plus';

export default {
  name: "pages.shortlisted-speakers.widget",
  components: {
    GenerateInvoice,
    GeneratePO,
    FilterShortlistedSpeakers,
    JetInput,
    AppSelect,
    Loading,
    GenerateFile,
    DateTimeInput,
  },
  data: () => ({
    openFilterShortlistedSpeakers: false,
    openGenerateInvoice: false,
    openGeneratePO: false,
    isAnySpeakerBooked: false,
    showHeldPopUpModal: false,
    eventEndDateVal: null,
    eventDateTime: null,
    isBookedPopUp: false,
    invoiceLoaded: false,
    updateStatusData: {},
    notDateFound: false,
    additionalDates: [],
    dealInfo: {
      deal_id: null,
      name: null,
      function_date: null,
      additionalFunctionDatesSubform: null,
      client_name: null,
      booking_number: null,
      acc_id: null,
      contactId: null,
      currency: null,
    },
    commissionType:[ { id: "%", label: "Percent" },
      { id: "$", label: "Dollar" }],
    emails: [],
    testing: null,
    again: null,
    speakerList: [],
    shortlistedSpeakerList: [],
    shortListedSpeakersInfo: [],
    allShortlistedPOInfo: [],
    allShortlistedSpeakerInfo: [],
    calculatedBill: 0,
    statusOptions: [
      { id: "-None-", label: "-None-" },
      { id: "Held", label: "Held" },
      { id: "Booked", label: "Booked" },
      { id: "Released", label: "Released" },
      { id: "Postponed", label: "Postponed" },
      { id: "Rejected", label: "Rejected" },
      { id: "Cancelled", label: "Cancelled" },
    ],
    selectedValue: null,
    loadingText: "Please Wait...",
    showLoadingScreen: false,
    openGenerateFileModal: false,
    loading: false,
    showPopUpModal: false,
    message: null,
    commission: null,
    showTick: false,
    speakerFeeData: {},
    blockMargin: "32px",
    size: "small",
    shortlisted_speaker_crm_url: process.env.VUE_APP_SHS_CRM_URL,
    speaker_crm_url: process.env.VUE_APP_SPEAKER_CRM_URL,

  }),
  async mounted() {
    await this.init();
    document.querySelector(".home-menu");
  },

  /* global ZOHO */
  methods: {
    async init() {
      this.showLoadingScreen = true;
      ZOHO.embeddedApp.on("PageLoad", async (data) => {
        let entityId = data.EntityId;
        await this.syncShortlistedSpeaker(entityId);
        await ZOHO.CRM.API.getRecord({
          Entity: data.Entity,
          RecordID: data.EntityId,
        }).then((response) => {
          let data = response.data[0];
          // Keep track of fields from the Opportunity.
          this.updatedDealFields(data);
          if (this.dealInfo.client_name === undefined) {
            this.loadingText = "Please assign a contact.";
            this.showPopUpNotification("Contact Not Assigned");
            return false;
          }

          this.fetchShortlistedSpeaker(data.id);
          this.shortListedspeakersBill(data.id);
        });
      });
      ZOHO.embeddedApp.init();
      this.loading = false;
    },
    async processGenerateInvoice() {
      if (this.dealInfo.deal_id) {
        this.showLoadingScreen = true;
        const recordDetails = await ZOHO.CRM.API.getRecord({
          Entity: "Deals",
          RecordID: this.dealInfo.deal_id,
        });
        let dealInfo = recordDetails.data[0];
        let error_notification = "";
        
        if (dealInfo.Function_Date == null) {
          this.showLoadingScreen = false;
          this.showPopUpNotification(
            "Function Date is null. Please set a function date."
          );
          return false;
        }
        this.updatedDealFields(dealInfo);
        await this.shortListedspeakersBill(this.dealInfo.deal_id);
        const noAmountSpeakerList = this.shortListedSpeakersInfo.filter(
          (speaker) => speaker.listprice == 0
        );
        noAmountSpeakerList.forEach((speaker_details) => {
          error_notification += speaker_details.name + "'s amount is empty. \n";
        });
        if (this.shortListedSpeakersInfo.length == 0) {
          this.showLoadingScreen = false;
          this.showPopUpNotification("The invoices for all shortlisted speakers have been generated.");
          return false;
        }
        if (error_notification) {
          this.showLoadingScreen = false;
          this.showPopUpNotification(error_notification);
          return false;
        }
        

        this.invoiceLoaded = false;
        this.openGenerateInvoice = true;
      }
    },
    updatedDealFields(data) {
      this.dealInfo.deal_id = data.id;
      this.dealInfo.name = data.Deal_Name;
      this.dealInfo.function_date = data.Function_Date
        ? data.Function_Date
        : getLocalISOTime(new Date().toISOString());
      this.dealInfo.client_name = data.Contact_Name?.name;
      this.dealInfo.contactId = data.Contact_Name?.id;
      this.dealInfo.booking_number = data.Enquiry_Booking_Number;
      this.dealInfo.acc_id = data.Account_Name?.id;
      this.dealInfo.inv_status = data.Invoice_Status;
      this.dealInfo.saxton_or_csnz = data.Saxton_or_CSNZ;
      this.dealInfo.consultant = data.Consultant?.name;
      this.dealInfo.co_ordinator = data.Co_Ordinator?.name;
      this.dealInfo.currency = data.Currency;
      this.dealInfo.additionalFunctionDatesSubform = data.Additional_Functions;
    },
    async processGeneratePO() {
      if (this.dealInfo.deal_id) {
        this.showLoadingScreen = true;
        const recordDetails = await ZOHO.CRM.API.getRecord({
          Entity: "Deals",
          RecordID: this.dealInfo.deal_id,
        });
        let dealInfo = recordDetails.data[0];
        let error_notification = "";

        if (dealInfo.Invoice_Status != "Created") {
          this.showLoadingScreen = false;
          this.showPopUpNotification("Invoice is not created.");
          return false;
        }

        this.updatedDealFields(dealInfo);

        await this.shortListedspeakersBill(this.dealInfo.deal_id);

        if (this.allShortlistedPOInfo.length == 0) {
          this.showLoadingScreen = false;
          this.showPopUpNotification("The Purchase Order for all shortlisted speakers have been generated.");
          return false;
        }

        const noAmountSpeakerList = this.allShortlistedPOInfo.filter(
          (speaker) => speaker.listprice == 0 || speaker.commission == null
        );
        noAmountSpeakerList.forEach((speaker_details) => {
          error_notification +=
            speaker_details.name + "'s amount/commission is empty. \n";
        });
        if (error_notification) {
          this.showLoadingScreen = false;
          this.showPopUpNotification(error_notification);
          return false;
        }
        this.openGeneratePO = true;
      }
    },
    showPopUpNotification(msg) {
      this.message = msg;
      this.showPopUpModal = true;
      this.openGenerateInvoice = false;
    },
    async shortListedspeakersBill(dealId) {
      await ZOHO.CRM.API.getRelatedRecords({
        Entity: "Deals",
        RecordID: dealId,
        RelatedList: "Speakers5",
      }).then((response) => {
        if (response.status != 204) {
           
          const calBill = this.calulateSpeakerBill(response.data);
          this.calculatedBill = !isNaN(Number(calBill)) ? calBill : 0;
          this.showLoadingScreen = false;
        } else {
          this.showLoadingScreen = false;
        }
      });
    },
    async syncShortlistedSpeaker(dealId) {
      this.loadingText = "Syncing Speakers...";
      await syncShortlistedSpeaker(dealId);
    },
    fetchShortlistedSpeaker(dealId) {
      this.loadingText = "Fetching Speaker Data...";
      fetchShortlistedSpeakers(dealId, this.dealInfo.function_date)
        .then((res) => {
          if (res.data) {
            let bookedShortlistedSpeakers = res.data.filter(
              (speaker) => speaker.status === "Booked"
            );

            this.speakerList = [];

            res.data.forEach((element) => {
              this.speakerList.push(element.speaker_id);
            });

            let notBookedShortlistedSpeakers = res.data.filter(
              (speaker) => speaker.status != "Booked" || speaker.status == null
            );

            this.shortlistedSpeakerList = bookedShortlistedSpeakers.concat(
              notBookedShortlistedSpeakers
            );

            if (
              this.shortlistedSpeakerList.find(
                (speaker) => speaker.status === "Booked"
              )
            ) {
              this.isAnySpeakerBooked = true;
            }
          }
        })
        .catch((err) => console.log(err));
    },
    calulateSpeakerBill(shortListedSp) {
      let calBill = 0;
      this.shortListedSpeakersInfo = [];
      this.allShortlistedSpeakerInfo = [];
      this.allShortlistedPOInfo = [];
      shortListedSp.forEach((slDetails) => {
        let speakerDetails = {
          name: slDetails.Shortlisted_Speakers.name,
          id: slDetails.Shortlisted_Speakers.id,
          ss_id: slDetails.id,
          listprice: 0,
          all_listprice: 0,
          commission: slDetails.Commission,
          commissionType: null,
          shortlistedSpId: slDetails.id,
          Live_Keynote_Fee: null,
          In_Conversation_Fee: null,
          Virtual_Keynote_Fee_Livestream: null,
          //Virtual_Keynote_Fee_Pre_recorded: null,
          Full_Day_Workshop_Fee: null,
          Half_Day_Workshop_Fee: null,
          MC_Half_Day_Fee: null,
          MC_Full_Day_Fee: null,
          Education_NFP_Fee: null,
          Campaign_Fee: null,
          Other_Fee: null,
          commission_percent: slDetails.Commission2,
          commission_amount: slDetails.Commission1,
          Allow_LKF_to_Qwilr: slDetails.Allow_LKF_to_Qwilr,
          Allow_ICF_to_Qwilr: slDetails.Allow_ICF_to_Qwilr,
          Allow_VKFL_to_Qwilr: slDetails.Allow_VKFL_to_Qwilr,
          Allow_FDWF_to_Qwilr: slDetails.Allow_FDWF_to_Qwilr,
          Allow_HDWF_to_Qwilr: slDetails.Allow_HDWF_to_Qwilr,
          Allow_MCHDF_to_Qwilr: slDetails.Allow_MCHDF_to_Qwilr,
          Allow_MCFDF_to_Qwilr: slDetails.Allow_MCFDF_to_Qwilr,
          Allow_ENF_to_Qwilr: slDetails.Allow_ENF_to_Qwilr,
          Allow_CF_to_Qwilr: slDetails.Allow_CF_to_Qwilr,
          Allow_OF_to_Qwilr: slDetails.Allow_OF_to_Qwilr,
          Live_Keynote_Fee: Number(slDetails.Live_Keynote_Fee),
          In_Conversation_Fee: Number(slDetails.In_Conversation_Fee),
          Virtual_Keynote_Fee_Livestream: Number(slDetails.Virtual_Keynote_Fee_Livestream),
          Full_Day_Workshop_Fee: Number(slDetails.Full_Day_Workshop_Fee),
          Half_Day_Workshop_Fee: Number(slDetails.Half_Day_Workshop_Fee),
          MC_Half_Day_Fee: Number(slDetails.MC_Half_Day_Fee),
          MC_Full_Day_Fee: Number(slDetails.MC_Full_Day_Fee),
          Education_NFP_Fee: Number(slDetails.Education_NFP_Fee),
          Campaign_Fee: Number(slDetails.Campaign_Fee),
          Other_Fee: Number(slDetails.Other_Fee),
        };
        if (slDetails.Status == "Booked" && slDetails.Invoice_Created == false) {
          //Allow ENF to Qwilr
          if (slDetails.Allow_LKF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Live_Keynote_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Live_Keynote_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Live_Keynote_Fee);

            // speakerDetails["Live_Keynote_Fee"] = Number(
            //   slDetails.Live_Keynote_Fee
            // );
            //console.log(calBill);
          }

          //Allow ICF to Qwilr
          if (slDetails.Allow_ICF_to_Qwilr == true)
          {
            calBill = calBill + Number(slDetails.In_Conversation_Fee);
            speakerDetails["listprice"] = 
              speakerDetails["listprice"] + Number(slDetails.In_Conversation_Fee);
            speakerDetails["all_listprice"] = 
              speakerDetails["all_listprice"] + Number(slDetails.In_Conversation_Fee);
          }

          //Allow VKFL to Qwilr
          if (slDetails.Allow_VKFL_to_Qwilr == true) {
            calBill =
              calBill + Number(slDetails.Virtual_Keynote_Fee_Livestream);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Virtual_Keynote_Fee_Livestream);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] +
              Number(slDetails.Virtual_Keynote_Fee_Livestream);

            // speakerDetails["Virtual_Keynote_Fee_Livestream"] = Number(
            //   slDetails.Virtual_Keynote_Fee_Livestream
            // );
            //console.log(calBill);
          }


          //Allow FDWF to Qwilr

          if (slDetails.Allow_FDWF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Full_Day_Workshop_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Full_Day_Workshop_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] +
              Number(slDetails.Full_Day_Workshop_Fee);

            // speakerDetails["Full_Day_Workshop_Fee"] = Number(
            //   slDetails.Full_Day_Workshop_Fee
            // );
            //console.log(calBill);
          }

          //Allow HDWF to Qwilr

          if (slDetails.Allow_HDWF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Half_Day_Workshop_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Half_Day_Workshop_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] +
              Number(slDetails.Half_Day_Workshop_Fee);

            // speakerDetails["Half_Day_Workshop_Fee"] = Number(
            //   slDetails.Half_Day_Workshop_Fee
            // );
            //console.log(calBill);
          }

          //Allow MCHDF to Qwilr

          if (slDetails.Allow_MCHDF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.MC_Half_Day_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.MC_Half_Day_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.MC_Half_Day_Fee);

            // speakerDetails["MC_Half_Day_Fee"] = Number(
            //   slDetails.MC_Half_Day_Fee
            // );

            //console.log(calBill);
          }

          //Allow MCFDF to Qwilr

          if (slDetails.Allow_MCFDF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.MC_Full_Day_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.MC_Full_Day_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.MC_Full_Day_Fee);

            // speakerDetails["MC_Full_Day_Fee"] = Number(
            //   slDetails.MC_Full_Day_Fee
            // );
            //console.log(calBill);
          }

          //Allow ENF to Qwilr

          if (slDetails.Allow_ENF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Education_NFP_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Education_NFP_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Education_NFP_Fee);

            // speakerDetails["Education_NFP_Fee"] = Number(
            //   slDetails.Education_NFP_Fee
            // );
            //console.log(calBill);
          }

          //Allow Campaign Fee to Qwilr

          if (slDetails.Allow_CF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Campaign_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Campaign_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Campaign_Fee);
          }
          //speakerDetails["Campaign_Fee"] = Number(slDetails.Campaign_Fee);
          //console.log(calBill);

          //Allow Other Fee to Qwilr

          if (slDetails.Allow_OF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Other_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Other_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Other_Fee);

            //speakerDetails["Other_Fee"] = Number(slDetails.Other_Fee);
            //console.log(calBill);
          }
          this.shortListedSpeakersInfo.push(speakerDetails);
          this.allShortlistedSpeakerInfo.push(speakerDetails);
        } else {
          //Allow ENF to Qwilr
          if (slDetails.Allow_LKF_to_Qwilr == true) {
            // speakerDetails["Live_Keynote_Fee"] = Number(
            //   slDetails.Live_Keynote_Fee
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Live_Keynote_Fee);
            //console.log(calBill);
          }

          //Allow ICF to Qwilr
          if (slDetails.Allow_ICF_to_Qwilr == true)
          {
            speakerDetails["all_listprice"] = 
              speakerDetails["all_listprice"] + Number(slDetails.In_Conversation_Fee);
          }

          //Allow VKFL to Qwilr
          if (slDetails.Allow_VKFL_to_Qwilr == true) {
            // speakerDetails["Virtual_Keynote_Fee_Livestream"] = Number(
            //   slDetails.Virtual_Keynote_Fee_Livestream
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Virtual_Keynote_Fee_Livestream);
            //console.log(calBill);
          }


          //Allow FDWF to Qwilr

          if (slDetails.Allow_FDWF_to_Qwilr == true) {
            // speakerDetails["Full_Day_Workshop_Fee"] = Number(
            //   slDetails.Full_Day_Workshop_Fee
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Full_Day_Workshop_Fee);
            //console.log(calBill);
          }

          //Allow HDWF to Qwilr

          if (slDetails.Allow_HDWF_to_Qwilr == true) {
            // speakerDetails["Half_Day_Workshop_Fee"] = Number(
            //   slDetails.Half_Day_Workshop_Fee
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Half_Day_Workshop_Fee);
            //console.log(calBill);
          }

          //Allow MCHDF to Qwilr

          if (slDetails.Allow_MCHDF_to_Qwilr == true) {
            // speakerDetails["MC_Half_Day_Fee"] = Number(
            //   slDetails.MC_Half_Day_Fee
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.MC_Half_Day_Fee);

            //console.log(calBill);
          }

          //Allow MCFDF to Qwilr

          if (slDetails.Allow_MCFDF_to_Qwilr == true) {
            // speakerDetails["MC_Full_Day_Fee"] = Number(
            //   slDetails.MC_Full_Day_Fee
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.MC_Full_Day_Fee);
            //console.log(calBill);
          }

          //Allow ENF to Qwilr

          if (slDetails.Allow_ENF_to_Qwilr == true) {
            // speakerDetails["Education_NFP_Fee"] = Number(
            //   slDetails.Education_NFP_Fee
            // );
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Education_NFP_Fee);
            //console.log(calBill);
          }

          //Allow Campaign Fee to Qwilr

          if (slDetails.Allow_CF_to_Qwilr == true) {
            //speakerDetails["Campaign_Fee"] = Number(slDetails.Campaign_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Campaign_Fee);
            //console.log(calBill);
          }
          //Allow Other Fee to Qwilr

          if (slDetails.Allow_OF_to_Qwilr == true) {
            //speakerDetails["Other_Fee"] = Number(slDetails.Other_Fee);
            speakerDetails["all_listprice"] =
              speakerDetails["all_listprice"] + Number(slDetails.Other_Fee);
            //console.log(calBill);
          }

          this.allShortlistedSpeakerInfo.push(speakerDetails);
        }

        if(slDetails.Status == "Booked" && slDetails.Invoice_Created == true && slDetails.PO_Created == false) {
          //Allow ENF to Qwilr
          if (slDetails.Allow_LKF_to_Qwilr == true) {
            // speakerDetails["Live_Keynote_Fee"] = Number(
            //   slDetails.Live_Keynote_Fee
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Live_Keynote_Fee);
            //console.log(calBill);
          }

          if (slDetails.Allow_ICF_to_Qwilr == true)
          {
            speakerDetails["listprice"] = 
              speakerDetails["listprice"] + Number(slDetails.In_Conversation_Fee);
          }

          //Allow VKFL to Qwilr
          if (slDetails.Allow_VKFL_to_Qwilr == true) {
            // speakerDetails["Virtual_Keynote_Fee_Livestream"] = Number(
            //   slDetails.Virtual_Keynote_Fee_Livestream
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Virtual_Keynote_Fee_Livestream);
            //console.log(calBill);
          }


          //Allow FDWF to Qwilr

          if (slDetails.Allow_FDWF_to_Qwilr == true) {
            // speakerDetails["Full_Day_Workshop_Fee"] = Number(
            //   slDetails.Full_Day_Workshop_Fee
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Full_Day_Workshop_Fee);
            //console.log(calBill);
          }

          //Allow HDWF to Qwilr

          if (slDetails.Allow_HDWF_to_Qwilr == true) {
            // speakerDetails["Half_Day_Workshop_Fee"] = Number(
            //   slDetails.Half_Day_Workshop_Fee
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Half_Day_Workshop_Fee);
            //console.log(calBill);
          }

          //Allow MCHDF to Qwilr

          if (slDetails.Allow_MCHDF_to_Qwilr == true) {
            // speakerDetails["MC_Half_Day_Fee"] = Number(
            //   slDetails.MC_Half_Day_Fee
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.MC_Half_Day_Fee);

            //console.log(calBill);
          }

          //Allow MCFDF to Qwilr

          if (slDetails.Allow_MCFDF_to_Qwilr == true) {
            // speakerDetails["MC_Full_Day_Fee"] = Number(
            //   slDetails.MC_Full_Day_Fee
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.MC_Full_Day_Fee);
            //console.log(calBill);
          }

          //Allow ENF to Qwilr

          if (slDetails.Allow_ENF_to_Qwilr == true) {
            // speakerDetails["Education_NFP_Fee"] = Number(
            //   slDetails.Education_NFP_Fee
            // );
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Education_NFP_Fee);
            //console.log(calBill);
          }

          //Allow Campaign Fee to Qwilr

          if (slDetails.Allow_CF_to_Qwilr == true) {
            //speakerDetails["Campaign_Fee"] = Number(slDetails.Campaign_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Campaign_Fee);
            //console.log(calBill);
          }
          //Allow Other Fee to Qwilr

          if (slDetails.Allow_OF_to_Qwilr == true) {
            //speakerDetails["Other_Fee"] = Number(slDetails.Other_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Other_Fee);
            //console.log(calBill);
          }

          this.allShortlistedPOInfo.push(speakerDetails);
          
        }
      });

      return calBill;
    },
    keyNoteValueChangeHandle(event,sec_field_api_name){
      const inputEvt = event.currentTarget;
      
      let key_field_name = inputEvt.id.split("-")[0];
     
      let spId = inputEvt.id.split("-")[1];
      let keyValue = '';
      let old_value = '';
      let anotherFieldVal = '';
      let anotherFieldOldVal = '';

      if (key_field_name.includes('Allow'))
      {
         old_value = this.getAllowedCheckboxVal(spId,key_field_name);
         keyValue = event.target.checked;
         const anotherField = document.querySelector("#"+sec_field_api_name+"-" + spId);
         anotherFieldVal = anotherField.value;
         anotherFieldOldVal = this.getFeeAmount(spId,sec_field_api_name);
      } else {
         old_value = this.getFeeAmount(spId,key_field_name);
         keyValue = event.target.value;
         const anotherField = document.querySelector("#"+sec_field_api_name+"-" + spId);
         anotherFieldVal = anotherField.checked;
         anotherFieldOldVal = this.getAllowedCheckboxVal(spId,sec_field_api_name);
      }
      const buttonDiv = document.querySelector("."+key_field_name+"-" + spId);
      if (keyValue == old_value && anotherFieldVal == anotherFieldOldVal) {
        buttonDiv.style.display = 'none';
      } else {
        buttonDiv.style.display = 'block';
      }
    },
    checkCom(shpId,option) {

      let com = this.allShortlistedSpeakerInfo.filter(
        (ssp) => ssp.shortlistedSpId == shpId
      );
      if (com.length > 0) {
        let ComType = ((com[0].commission != null) && com[0].commission.includes(option)) ? true : false;
        return ComType;
      }
      return false;

      
    },
    optionPicked(event, speaker) {
  
      const showTick = document.querySelector(".showTick-" + speaker.shortlisted_speaker_crm_id);
      showTick.style.display = 'block';

    },
    selectOption(event, speaker) {
      let eventLogName = this.dealInfo.name+" " + speaker.speaker.speaker_name  +" "+this.dealInfo.booking_number;
      if (eventLogName.length >= 255)
      {
        eventLogName = eventLogName.substring(0,eventLogName.length - 3);
      }
      let data = {
        status: event.target.value,
        shortlisted_speaker_crm_id: speaker.shortlisted_speaker_crm_id,
        opportunity_id: this.dealInfo.deal_id,
        eventLogisticName: eventLogName,
        speaker_crm_id: speaker.speaker.crm_id,
        speaker_id: speaker.id,
        event_start_date: null,
        event_end_date: null,
        addtional_dates: [],
        deal_name: this.dealInfo.name,
        function_date: this.dealInfo.function_date,
      };

      if (event.target.value != speaker.status) {
        if (event.target.value == "Held" || event.target.value == "Booked") {
          // Prepopulate 'Additional Date' ranges in the 'Book Date Range' modal.
          this.additionalDates = [];
          this.dealInfo.additionalFunctionDatesSubform.forEach((subformRow) => {
            if (subformRow.Additional_Date != null)
            {
              // Convert string to a Date object. Force it to be at midnight.
              var additionalDate = new Date(subformRow.Additional_Date);

              // Add the 2 dates to our list to render in the calendar 
              // as a range correctly.
              var additionalDateRange = [];
              additionalDateRange.push(...[additionalDate, additionalDate]);

              this.additionalDates.push({'date_time':additionalDateRange});
            }
          });
          
          // Convert string to a Date object. Force it to be at midnight.
          var functionDate = this.dealInfo.function_date;
          var functionDateForRange = new Date(functionDate);

          // Add the 2 dates to our list to render in the calendar 
          // as a range correctly.
          var dateRange = [];
          dateRange.push(...[functionDateForRange, functionDateForRange]);

          this.eventDateTime = dateRange;

          this.showHeldPopUpModal = true;
          this.updateStatusData = data;
          this.isBookedPopUp = event.target.value == "Booked" ? true : false;
        } else {
          this.loadingText = "Updating Status...";
          this.loading = true;
          updateShortlistedSpeaker(speaker.id, data)
            .then((res) => {
              if (res) {
                this.loading = false;
                speaker.status = event.target.value;
              }
            })
            .catch((err) => console.log(err));
        }
      }
    },
    updateStatus() {
      if (this.eventDateTime != null) {
        this.showHeldPopUpModal = false;
        this.notDateFound = false;
        this.loadingText = "Updating Status...";
        this.loading = true;
        this.updateStatusData["event_start_date"] = this.eventDateTime[0];
        this.updateStatusData["event_end_date"] = this.eventDateTime[1];
        this.updateStatusData["addtional_dates"] = this.additionalDates;
        // return false;
        updateShortlistedSpeaker(
          this.updateStatusData.speaker_id,
          this.updateStatusData
        )
          .then((res) => {
            if (res) {
              this.loading = false;
              let status = document.querySelector(
                ".status-" + this.updateStatusData.speaker_crm_id
              );
              status.value = this.updateStatusData.status;

              this.fetchShortlistedSpeaker(this.dealInfo.deal_id);
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.notDateFound = true;
      }
    },
    cancelUpdateStatus() {
      let status = document.querySelector(
        ".status-" + this.updateStatusData.speaker_crm_id
      );
      status.value = "";
      this.eventDateTime = null;
      this.additionalDates = [];
      this.showHeldPopUpModal = false;
      this.updateStatusData = {};
      this.notDateFound = false;
    },
    async generateFile(speaker) {
      this.showLoadingScreen = true;
      let opportunity_id = this.dealInfo.deal_id;
      let speaker_id = speaker.speaker.crm_id;
      let shortlistedSpId = speaker.shortlisted_speaker_crm_id;
      let arg = { opportunity_id: opportunity_id, speaker_id: speaker_id };

      let data = {
        file_generated: true,
      };

      try {
        const res = await generateFile(arg);
        if (res.data.status == "success") {
          const update_res = await updateShortlistedSpeaker(
            shortlistedSpId,
            data
          );
          if (update_res) {
            this.showLoadingScreen = false;
            this.message =
              "Your document is being generated. Please refresh the window after few minutes to see the document in the attachments";
            this.showPopUpModal = true;
          }
        } else {
          this.showLoadingScreen = false;
          this.data.message = res.data.message;
          this.showPopUpModal = true;
        }
      } catch (err) {
        this.showLoadingScreen = false;
        this.showPopUpModal = true;
        this.message =
          "File is not generated sucessfully.Error Details: " + err;
        console.log(err);
      }
    },
    changeColor(status) {
      let color;
      switch (status) {
        case "Held":
          color = "text-blue-500";
          break;
        case "Booked":
          color = "text-green-500";
          break;
        case "Released":
          color = "text-red-500";
          break;
        case "Cancelled":
          color = "text-orange-500";
          break;
        case "Postponed":
          color = "text-yellow-500";
          break;
        default:
          color = "text-gray-200";
      }
      return color;
    },
    async updateCommission(sp_id) {
      let commission = document.getElementById("input-" + sp_id);
      let select = document.getElementById("comtype-" + sp_id);
     
      let commissionType     = select.value;

      let shSpDetails = this.allShortlistedSpeakerInfo.filter(
        (ssp) => ssp.shortlistedSpId == sp_id
      );
      

      if (isNaN(commission.value))
      {
        ElMessage({
          type: 'error',
          message: 'Only numbers are allowed.',
        })
        return false;
      }
      let newCommissionValue = "";
      if (commissionType == "%")
      {
        newCommissionValue = commission.value+commissionType;
        if (commission.value > 100)
        {
          ElMessage({
            type: 'error',
            message: 'Percentage can not be more than 100',
          })
          return false;
        }
      } else {
        if (shSpDetails.length > 0 && commission.value > shSpDetails[0].all_listprice) {
    
          ElMessage({
            type: 'error',
            message: 'Dollar amount should be less than total amount of Fees.',
          })
          return false;
        }
        newCommissionValue = commissionType+commission.value;
      }
      
    
      ElMessageBox.confirm(
        'Are you sure you want to update this commission?',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(async ()  => {
          var data = {
            Entity: "Opportunities_X_Speakers",
            APIData: {
              id: sp_id,
              Commission: newCommissionValue,
            },
            Trigger: ["workflow"],
          };
          const update_res = await ZOHO.CRM.API.updateRecord(data);
          if (update_res) {
            this.shortListedspeakersBill(this.dealInfo.deal_id);
            ElMessage({
              type: 'success',
              message: 'Commission updated Successfully!',
            })
          }        
        })
        .catch(() => {
          commission.value = this.getSSCommission(sp_id);
          let com = this.allShortlistedSpeakerInfo.filter(
            (ssp) => ssp.shortlistedSpId == sp_id
          );
          if (com.length > 0)
          {
            if (com[0].commission.includes("$"))
            {
              select.value = "$";
            }
            if (com[0].commission.includes("%"))
            {
              select.value = "%";
            }
          }
          ElMessage({
            type: 'info',
            message: 'Update canceled',
          })
        })
        
      const showTick = document.querySelector(".showTick-" + sp_id);
      showTick.style.display = "none";
    },
    getCommission(event) {
      const inputEvt = event.currentTarget;
      let spId = inputEvt.id.split("-")[1];
      let commissionValue = event.target.value;
      let oldValue = this.getSSCommission(spId);
      const showTick = document.querySelector(".showTick-" + spId);
      if (commissionValue == oldValue) {
        showTick.style.display = "none";
      } else {
        showTick.style.display = "block";
      }
    },
    getSSCommission(sp_id) {
      let com = this.allShortlistedSpeakerInfo.filter(
        (ssp) => ssp.shortlistedSpId == sp_id
      );
      if (com.length > 0) {
        let com_ammount = (com[0].commission != null) ? com[0].commission.replace(/%|\$/gi,"").trim() : "";
        return com_ammount;
      }
      return 0;
    },
    getFeeAmount(ssp_id, api_name) {
      let spd = this.allShortlistedSpeakerInfo.filter(
        (ssp) => ssp.shortlistedSpId == ssp_id
      );
      if (spd.length > 0) {
        return spd[0][api_name] != null ? spd[0][api_name] : 0;
      }
      return 0;
    },
    async updateFeeAmount(ssp_id, api_name,allow_field_name)
    {

      let fieldValue = document.querySelector("#"+api_name + "-" + ssp_id);
      let allow_field = document.querySelector("#"+allow_field_name + "-" + ssp_id);
      ElMessageBox.confirm(
        'Are you sure you want to update the fields?',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(async ()  => {
          var data = {
            Entity: "Opportunities_X_Speakers",
            APIData: {
              id: ssp_id
            },
            Trigger: ["workflow"],
          };        
          data['APIData'][api_name] = fieldValue.value;
          data['APIData'][allow_field_name] = allow_field.checked;
 
          const update_res = await ZOHO.CRM.API.updateRecord(data);
          if (update_res) {
            this.shortListedspeakersBill(this.dealInfo.deal_id);
            ElMessage({
              type: 'success',
              message: 'Field updated Successfully!',
            })
          }
          
        })
        .catch(() => {
          fieldValue.value =  this.getFeeAmount(ssp_id,api_name);
          allow_field.checked =  this.getAllowedCheckboxVal(ssp_id,allow_field_name);
          ElMessage({
            type: 'info',
            message: 'Update canceled',
          })
        })
        const buttonDiv = document.querySelector("."+api_name+"-" + ssp_id);
        buttonDiv.style.display = 'none';

    },
    async updateAllowQwilr(event,ssp_id, api_name)
    {
      let getFeeValue = event.target.checked;
      let fieldValue = document.querySelector("#"+api_name + "-" + ssp_id);

      ElMessageBox.confirm(
        'Are you sure you want to update the checkbox?',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(async ()  => {
          var data = {
            Entity: "Opportunities_X_Speakers",
            APIData: {
              id: ssp_id
            },
            Trigger: ["workflow"],
          };
          data['APIData'][api_name] = getFeeValue;
          
          const update_res = await ZOHO.CRM.API.updateRecord(data);
          if (update_res) {
            this.shortListedspeakersBill(this.dealInfo.deal_id);
            ElMessage({
              type: 'success',
              message: 'Checkbox updated Successfully!',
            })
          }        
        })
        .catch(() => {
          fieldValue.checked =  this.getAllowedCheckboxVal(ssp_id,api_name);
          ElMessage({
            type: 'info',
            message: 'Update canceled',
          })
        })
    },
    getAllowedCheckboxVal(ssp_id, api_name){
      let spd = this.allShortlistedSpeakerInfo.filter(
        (ssp) => ssp.shortlistedSpId == ssp_id
      );
      if (spd.length > 0) {
        return spd[0][api_name] == true ? true : false;
      }
      return false;
    },
    adjustDays(date, days, operation) {
      var result = new Date(date);
      if (operation == "Add") {
        result.setDate(result.getDate() + days);
      }

      if (operation == "Subtract") {
        result.setDate(result.getDate() - days);
      }
      return result;
    },
    async onHover(speakerId) {
      this.speakerFeeData = {};
      const recordDetails = await ZOHO.CRM.API.getRecord({
        Entity: "Speakers",
        RecordID: speakerId,
      });
      if (recordDetails.data) {
        let speakerDetails = recordDetails.data[0];
        this.speakerFeeData["Email"] = speakerDetails.Email;
        this.speakerFeeData["Campaign_Fee"] = speakerDetails.Campaign_Fee;
        this.speakerFeeData["Live_Workshop_Fee"] =
          speakerDetails.Live_Workshop_Fee;
        this.speakerFeeData["Half_Day_Workshop_Fee"] =
          speakerDetails.Half_Day_Workshop_Fee;
        this.speakerFeeData["Live_Keynote_Fee"] =
          speakerDetails.Live_Keynote_Fee;
        this.speakerFeeData["In_Conversation_Fee"] = 
          speakerDetails.In_Conversation_Fee;
        this.speakerFeeData["Other_Fee"] = speakerDetails.Other_Fee;
        this.speakerFeeData["Virtual_Keynote_Livestream_Fee"] =
          speakerDetails.Virtual_Keynote_Livestream_Fee;
        this.speakerFeeData["MC_Rate"] = speakerDetails.MC_Rate;
        this.speakerFeeData["MC_Full_Day"] = speakerDetails.MC_Full_Day;
        this.speakerFeeData["Gross_Fees_for_Dues"] =
          speakerDetails.Gross_Fees_for_Dues;
        this.speakerFeeData["Gross_Fees_Range"] =
          speakerDetails.Gross_Fees_Range;
        this.speakerFeeData["Education_NFP_Fee"] =
          speakerDetails.Education_NFP_Fee;
        this.speakerFeeData["City"] =
          speakerDetails.City;
        this.speakerFeeData["Agent_Management_Company"] =
          speakerDetails.Agent_Management_Company;
      }
    },
    addMore(){
      this.additionalDates.push({'date_time':''});
    }
  },
};
</script>

<style scoped>

  #shortlisted-speakers-table-head {
    position: sticky;
    top: -1px;
    opacity: 1;
    z-index: 2;
  }

  .z-1 {
    z-index: 1;
  }

  .bg-inherit {
    background-color: inherit;
  }

</style>
