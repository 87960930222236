<template>
  <div
    id="filter-shortlisted-speakers-modal"
    class="fixed z-4 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !isVisible,
    }"
  >
    <div class="p-2 border-1 rounded-lg w-3/4 h-3/4">
      <div class="h-3/5">
        <div class="p-4 bg-white rounded-lg w-full">
          <div class="flex justify-end px-2 py-1">
            <a @click="closeModal()" href="javascript:void(0)">
              <!-- <font-awesome-icon icon="fa-solid fa-xmark" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="20px"
              >
                <path
                  d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z"
                />
              </svg>
            </a>
          </div>
          <div class="flex justify-end">
            <app-label :label-for="`table-search`" class="sr-only"
              >Search</app-label
            >
            <div class="relative mt-1">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <app-input
                v-model:value="searchForm.search"
                focus-class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Speaker"
              />
            </div>
          </div>

          <div class="flex item-starts">
            <div class="flex h-5 items-center">
              <input
                id="candidates"
                name="candidates"
                type="checkbox"
                v-model="searchForm.advancedFiltering"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="candidates" class="font-medium text-gray-700"
                >Enable Advanced Filter</label
              >
            </div>
          </div>
          <transition name="fade">
          <div class="mt-4" v-if="searchForm.advancedFiltering">
            <div class="grid grid-cols-5 gap-3">
              <div>
                <app-input
                  v-model:value="searchForm.speaker_name"
                  :style="`px-1 py-2.5 text-xs`"
                  placeholder="Speaker Name"
                />
              </div>
              <div>
                <multiselect v-model="searchForm.gender" :options="genders" :multiple="false" :show-labels="false" placeholder="Gender" :class="`text-xs`" ></multiselect>
              </div>
            
              <div>
                <app-input
                  v-model:value="searchForm.topics"
                  :style="`px-1 py-2.5 text-xs`"
                  placeholder="Topic Title"
                />
              </div>
              <div>
                <multiselect v-model="searchForm.fee_range" :options="feeRanges" :multiple="false" :show-labels="false" placeholder="Gross Fee Range" :class="`text-xs`" ></multiselect>
              </div>
              <div>
                <app-input
                  :style="`px-1 py-2.5 text-xs`"
                  v-model:value="searchForm.live_keynote_fee"
                  placeholder="Live Keynote Fee"
                />
              </div>

              <div>
                <app-input
                  :style="`px-1 py-2.5 text-xs`"
                  v-model:value="searchForm.city"
                  placeholder="City"
                />
              </div>
              <div>
                <multiselect v-model="searchForm.state" :options="states" :multiple="false" :show-labels="false" placeholder="State" :class="`text-xs`" ></multiselect>
              </div>
              <div>
                <app-input
                  :style="`px-1 py-2.5 text-xs`"
                  v-model:value="searchForm.postcode"
                  placeholder="Post Code"
                />
              </div>

              <div>
                <multiselect v-model="searchForm.exclusivity" :options="exclusivities" :multiple="false" :show-labels="false" placeholder="Exclusivity" :class="`text-xs`" ></multiselect>        
              </div>
              <div>
                <app-input
                  v-model:value="searchForm.voice_over_name"
                  :style="`px-1 py-2.5 text-xs`"
                  placeholder="Voice Over Name"
                />
              </div>
              <div>
                <multiselect v-model="searchForm.industries" :options="industriesOptions" :multiple="true" :show-labels="false" placeholder="Industries"  :taggable="true" @tag="addIndustries" :class="`text-xs`" ></multiselect>
               
              </div>

              

              <div>
                <multiselect v-model="searchForm.categories" :options="categoriesOptions" :multiple="true" :show-labels="false" placeholder="Categories" :class="`text-xs`" ></multiselect>
              </div>  
              <div>
                <multiselect v-model="searchForm.keywords" :options="keywordsOptions" :multiple="true" :show-labels="false" placeholder="Keywords" :class="`text-xs`" ></multiselect>
              </div>
              <div>
                <multiselect v-model="searchForm.expertises" :options="expertisesOptions" :multiple="true" :show-labels="false" placeholder="Expertise" :class="`text-xs`" ></multiselect>
              </div>
              
            </div>
          </div>
          </transition>
          <div class="mt-4 flex justify-center gap-8">
            <button
              @click="filterSpeakers"
              class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150"
            >
              Search
            </button>
            <button
              @click="resetFilter"
              class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-red-500 shadow leading-6 rounded-md hover:bg-red-600 transition ease-in-out duration-150"
            >
              Reset
            </button>
          </div>

          <div class="flex mt-4">
            <div class="overflow-x-auto relative h-80">
              <table
                class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">No</th>
                    <th scope="col" class="px-6 py-3">Speaker Name</th>
                    <th scope="col" class="px-6 py-3">Conflict</th>
                    <th scope="col" class="px-6 py-3">Travels From</th>
                    <th scope="col" class="px-6 py-3"></th>
                    <th scope="col" class="px-6 py-3">Exclusivity</th>
                    <th scope="col" class="px-6 py-3">Status</th>
                    <th scope="col" class="px-6 py-3">Gross Fee Range</th>
                    <th scope="col" class="px-6 py-3">Live Keynote Fee</th>
                    <th scope="col" class="px-6 py-3">Account Name</th>
                    <th scope="col" class="px-6 py-3">Contact Name</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loadedSpeakerList.length > 0">
                    <tr
                      v-for="(speaker, index) in loadedSpeakerList"
                      :key="index"
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td class="px-6 py-4">
                        {{ index + 1 }}
                      </td>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        {{ speaker.speaker_name }}
                      </th>
                      <td class="px-6 py-4">
                        <div class="flex gap-x-4">
                          {{
                            speaker.availability == 1 || speaker.sp_exist == 1
                              ? "Yes"
                              : "No"
                          }}
                          <a
                            href="#"
                            @click="showConflict(speaker)"
                            v-if="
                              speaker.availability == 1 || speaker.sp_exist == 1
                            "
                            class="font-medium text-orange-600 dark:text-orange-500 hover:underline"
                            >Show</a
                          >
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.city }}
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap">
                        <a
                          href="#"
                          @click="noAssign(speaker)"
                          v-if="speaker.sp_exist == 1"
                          class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >Unassign</a
                        >

                        <a
                          href="#"
                          @click="occupy(speaker)"
                          v-else
                          class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >Assign</a
                        >
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.exclusivity }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.status }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.fee_range }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.live_keynote_fee }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.account_name }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.contact_name }}
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="9" class="text-center text-gray-700">
                      No Data Available!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-center px-2 py-2 gap-4">
            <!--                <button @click.prevent="createTemplate" class="px-4 py-1 text-white bg-green-400 rounded-md">Submit-->
            <!--                </button>-->

            <button
              class="px-4 py-1 text-gray-700 bg-white ring-1 ring-inset ring-gray-700 rounded-md"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="showLoadingScreen"
        class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen"
      >
        <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
          {{ loadingText }}
        </loading>
      </div>
    </div>
    <ShowConflict
      :dealInfo="dealInfo"
      :conflictSpeakerList="conflictSpeakerList"
      :speaker="selectedSpeaker"
      v-model:visible="openConflictSP"
      v-on:update:is-visible="this.$emit('update:is-visible', $event)"
    />
  </div>
</template>

<script>
import AppLabel from "@/components/AppLabel";
import AppInput from "@/components/AppInput";
import AppSelect from "@/components/AppSelect";
import ShowConflict from "@/views/Shortlisted-Speakers/ShowConflictSpeakers";
import {
  filterSpeakers,
  occupySpeakers,
  noAssignSpeaker,
  fetchConflictData,
} from "@/services/ShortlistedSpeakerService";
import Loading from "@/components/Loading";
import Multiselect from 'vue-multiselect';

const searchForm = {
  speaker_name: null,
  fee_range: null,
  exclusivity: null,
  live_keynote_fee: null,
  gender: null,
  state: null,
  city: null,
  postcode: null,
  categories: [],
  expertises: null,
  keywords: null,
  industries: [],
  topics: null,
  advancedFiltering: true,
  openConflictSP: false,
  search: null,
  function_date: null,
  selectedSpeaker: null,
  voice_over_name: null
};
export default {
  name: "pages.shortlisted-speakers.FilterShortlistedSpeakers",
  components: {
    AppLabel,
    AppInput,
    AppSelect,
    Loading,
    ShowConflict,
    Multiselect
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    dealInfo: {
      type: Object,
      required: true,
    },
    speakerList: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    genders: [
      "Male",
      "Female",
      "Non-Binary",
      "Group",
      "Other"
    ],
    industriesOptions:[],
    exclusivities: [
     "Exclusive",
     "Semi-Exclusive",
     "Non-Exclusive",
    ],
    states: [
       "Auckland",
       "Australian Capital Territory" ,
       "Bay of Islands",
       "Blenheim",
       "Christchurch" ,
       "Dunedin",
       "Gisborne",
       "Greymouth" ,
       "Hamilton",
       "Hastings",
       "Invercargill",
       "Napier",
       "Nelson",
       "New Plymouth"  ,
       "New South Wales",
       "Northern Territory",
       "Palmerston North" ,
       "Queensland"  ,
       "Queenstown"  ,
       "Rotorua",
       "South Australia",
       "Tasmania",
       "Taupo",
       "Tauranga",
       "Timaru",
       "Victoria",
       "Waikato",
       "Wellington",
       "Western Australia",
       "Whakatane",
       "Whangarei"
    ],
    feeRanges: [      
    "To be Confirmed",
    "Under $3,000", 
    "$3,000 to $5,000",
    "$5,000 to $8,000",
    "$8,000 to $15,000",
    "$15,000 to $20,000",
    "$21,000 to $49,000",
    "$50,000 to $100,000",
    "$101,000 and above"
    ],
    categoriesOptions: [
      "AFL & AFLW",
      "Aged Care Technology",
      "Aging & Aged Care",
      "America's Cup",
      "Artificial Intelligence",
      "Asia Speakers",
      "Athletics",
      "Australian Exclusives",
      "Blockchain",
      "Boxing",
      "Business",
      "Celebrity & Media",
      "CEO & Company Directors",
      "Challenge & Adventure",
      "Change Management",
      "Chefs",
      "Connection & Storytelling",
      "Creativity",
      "Cricket",
      "Culture",
      "Customer Service & Customer Experience",
      "Cyber Security",
      "Data Science",
      "Digital",
      "Disability",
      "Disruption",
      "Diversity & Inclusion Speakers",
      "Drones",
      "Economists & Economics",
      "Education",
      "Entrepreneurship",
      "Entrepreneurship & Entrepreneurs",
      "Environment & Climate Change",
      "Farming & Agriculture",
      "Future Cities",
      "Future of Work",
      "Futurists",
      "Gender Equality",
      "Golf",
      "Grief & Loss",
      "Health Technology",
      "Horse Racing",
      "Human Rights",
      "Inspiring Stories",
      "Intergenerational Leadership",
      "International Relations & Foreign Affairs",
      "Leadership",
      "Local Government",
      "Marketing",
      "Mastering Leadership",
      "Media Personality Speakers",
      "Mental Health",
      "Military",
      "Mindfulness",
      "Mindset",
      "Motorsport",
      "Netball",
      "Nutrition",
      "Olympics & Paralympics",
      "Other Sport",
      "Politicians",
      "Purpose & Vision",
      "Relationships",
      "Reputation",
      "Resilience",
      "Robotics",
      "Rugby Union & League",
      "Running",
      "Sales & Business Growth",
      "Science & Technology",
      "Social Enterprise",
      "Society, Culture and The Arts",
      "Stress",
      "Sustainability",
      "Swimming",
      "Trust & Ethics",
      "Women's Rights",
      "Work-Life Balance"
    ],
    keywordsOptions:  [
      "Accountant",
      "Activism Activists",
      "Actor or Actress",
      "Adaptability",
      "Addiction",
      "Adolescent Support",
      "Adopted",
      "Adventure",
      "Advertising and Branding",
      "Advocacy",
      "AFL (Australian Rules Football)",
      "AFLW",
      "After Dinner Speakers",
      "Aged Care Ageing",
      "Agility",
      "Agribusiness",
      "Agriculture",
      "Allied Health",
      "America's Cup",
      "Animals",
      "Antartica",
      "ANU Alumni",
      "Art",
      "Artificial Intelligence AI",
      "Artist",
      "Asia",
      "Astronaut",
      "Atlassian",
      "Attention-deficit/hyperactivity disorderADHD",
      "Auctioneers",
      "Auslan",
      "Authentic Leadership",
      "Authenticity",
      "Authors",
      "Autism",
      "Autoimmune Disease",
      "Automation",
      "Aviation",
      "B Corp",
      "B2B",
      "Bands",
      "Banking & Finance",
      "Basketball",
      "Beauty",
      "behavioural economics",
      "behavioural science",
      "Bi-Polar Disorder",
      "Big data",
      "BIPOC",
      "Bitcoin",
      "Blockchain",
      "Body Image",
      "Body Language",
      "Boxing",
      "Brain",
      "Brain Injury",
      "Brand",
      "Breathwork",
      "Brexit",
      "Bullying",
      "Burn Out",
      "Bushfire",
      "Business Development",
      "Business Transformation",
      "Cancer",
      "Cardiologist",
      "Celebrity",
      "Celebrity Chefs",
      "Celebrity Stylist",
      "Cerebal Palsy",
      "Cervical Cancer",
      "Change",
      "Change Management",
      "Charity and Philanthropy",
      "Children",
      "Children's author Children's books",
      "China",
      "Chronic Fatigue",
      "Circular Economy",
      "City Planning",
      "Cleft Palate",
      "Climate Change",
      "Cloud Computing",
      "Coach",
      "Collaboration",
      "Comedians",
      "Commonwealth Games",
      "Communication Skills",
      "Community",
      "Compassion",
      "Computers",
      "Conference Entertainers",
      "Confidence",
      "Conflict Management",
      "Consent",
      "Conservation",
      "Construction",
      "Consumer Behaviour",
      "Cooking Demo",
      "Coronavirus COVID-19",
      "Corporate Governance",
      "Corporate Innovation",
      "Corporate Social Responsibility (CSR)",
      "Courage",
      "Cover Bands",
      "Creativity and Innovation",
      "Cricket",
      "Crisis Management",
      "Crohn's Disease",
      "Cryptocurrency",
      "Cultural Awareness",
      "Cultural Change",
      "Cultural Competency",
      "Cultural Intelligence",
      "Cultural Safety",
      "Curiosity",
      "Current Affairs",
      "Customer Centricity",
      "Customer Experience",
      "Customer Service",
      "Cyber",
      "Cyber Safety",
      "Cybersecurity, Cyber Security",
      "Cycling & Cyclists",
      "Cystic Fibrosis",
      "Dance",
      "Deaf",
      "Deakin uni Alumni",
      "Defamation",
      "Dementia",
      "Demographer & Demographics",
      "Design",
      "Design Thinking",
      "Development",
      "Diabetes",
      "Difficult Conversations",
      "Digital Disruption",
      "Digital Habits",
      "Digital Marketing",
      "Digital Media",
      "Digital Transformation",
      "Disability Rights",
      "Diversity",
      "DJ",
      "Documentary",
      "Domestic Violence DFV",
      "Drag Queen",
      "Duo",
      "Eating disorder",
      "Economics & Economists",
      "Economy",
      "Education",
      "Emcees",
      "Emerging Technologies",
      "Emotional Intelligence",
      "Employee Experience",
      "Endurance",
      "Engineer",
      "Engineering",
      "Entertainment",
      "Entrepreneurs",
      "Environment",
      "eSports",
      "Ethics",
      "Events",
      "Exclusive",
      "Executive Coaching",
      "Executive Mentoring",
      "Executive retreats",
      "Experiential Marketing",
      "Facilitation & Facilitators",
      "Failure",
      "Family",
      "Farmer",
      "Fashion",
      "Female Empowerment",
      "FIFO",
      "Financial Wellness",
      "Fintech",
      "Fire Fighter",
      "Fishing",
      "Fitness",
      "FMCG",
      "Focus",
      "Food",
      "Forced Marriage",
      "Foreign Affairs",
      "Formula 1 Formula One",
      "foster care",
      "Franchising",
      "French Speaker",
      "Future",
      "Future Cities",
      "Future Trends",
      "Futurist",
      "Gambling",
      "Games & Gaming",
      "Gardener",
      "Gender",
      "Gender Equality",
      "Gender Non-Conforming",
      "Generational Change",
      "Geopolitics",
      "Globalisation",
      "Goals",
      "Golf",
      "Government",
      "Graphic Recorder",
      "Green Energy",
      "Hacker",
      "Headline Acts & Headline Artists",
      "Health & Lifestyle",
      "Heart Disease",
      "High Performance",
      "High Performing Teams",
      "History",
      "Hoax Characters",
      "Hockey",
      "Hope",
      "Horse Racing",
      "Host",
      "HR Human Resources",
      "Human trafficking",
      "Humour",
      "Hypnotism",
      "Hypothetical",
      "Immigration",
      "Impersonators",
      "Imposter Syndrome",
      "Inclusion",
      "Indigenous Issues",
      "Individual Skills",
      "Industry 5.0",
      "Industry 6.0",
      "Influencer",
      "Information Technology",
      "Innovation",
      "Inspiration",
      "Instrumentalists",
      "Intellectual Property (IP)",
      "International Business",
      "International Finance",
      "International Tax",
      "Internet",
      "Internet of Things (IOT)",
      "Intersectionality",
      "Investing",
      "IVF",
      "Jewellery",
      "Jewish Speakers",
      "Journalism & Journalists",
      "Justice",
      "Kayak",
      "Kiwis",
      "Landscaper",
      "Law",
      "Lawyer",
      "Leadership",
      "Leading Australian Speakers",
      "Learning",
      "Learning Disability",
      "Lego",
      "LGBTQIA+",
      "Lifestyle",
      "Live scribing",
      "Livestream",
      "Machine Learning",
      "Macro Economics",
      "Magazine",
      "Managing People",
      "Mandarin Speaker",
      "Manufacturing",
      "Maori Culture",
      "Marketing",
      "Martial Arts",
      "Master of Ceremonies (MCs), Emcees",
      "Mathematics & Maths",
      "Media",
      "Medical Research",
      "Medical Trials",
      "Medicine",
      "Meditation",
      "Melbourne Uni Alumni",
      "Men's Health",
      "Mental Health",
      "Mentor Me",
      "Metaverse",
      "Military Speakers",
      "Millenials",
      "Mindfulness",
      "Mindset",
      "Mining",
      "Modern Day Slavery",
      "Monash Uni Alumni",
      "Money",
      "Motivational",
      "Motor Sports & motorsport",
      "Motoring",
      "Music",
      "Natural Language Processing",
      "Negotiation Skills",
      "Networking",
      "Neuro Diverse Neurodiverse",
      "Neuro-Linguistic Programming (NLP)",
      "Neuroscience",
      "News",
      "NFTs",
      "Non Binary",
      "Not for Profit (NFP)",
      "Nurse",
      "Nutrition",
      "Occupational Health & Safety (OHS)",
      "Occupational Therapy",
      "Older Australian",
      "Olympic Speakers, Olympians and Paralympians",
      "On-line Marketing",
      "Online Behaviour",
      "Open Banking",
      "Opera",
      "Optimism & Optimist",
      "Organisational Culture",
      "Organisational Psychology",
      "Osteoarthritis",
      "Out of Home Care",
      "Outdoor Activities",
      "Painting",
      "Paralympics",
      "Parenting",
      "Pasifika",
      "Peak Performance",
      "Performers",
      "Perimenopause",
      "Period Poverty",
      "Personal Brand",
      "Personal Development",
      "Personal Experience",
      "Personal Finance",
      "Personal Trainer",
      "Pilot",
      "Play",
      "Podcast",
      "Poetry",
      "Police",
      "Politics",
      "Polycystic Ovary Syndrome (PCOS)",
      "Popular Culture",
      "Positive Psychology",
      "Post Natal Depression (PND)",
      "Post Traumatic Stress Disorder (PTSD)",
      "Presentation Skills",
      "Prime Minister",
      "Prison",
      "Privacy",
      "Problem Solving",
      "Productivity",
      "Property Experts",
      "PropTech",
      "Prostate Cancer",
      "psoriasis",
      "Psychology & Psychologists",
      "Purpose",
      "Racism",
      "Radio",
      "Real Estate",
      "Reality TV",
      "Reconciliation",
      "Reconciliation Action Plan",
      "Recovery",
      "Recruitment",
      "Refugee",
      "Regional",
      "Relationships",
      "Religion",
      "Remote Teams",
      "Remote workplace culture",
      "Renewable Energy",
      "Reporter",
      "Resilience",
      "Restaurants",
      "Retail",
      "Risk",
      "RMIT Alumni",
      "Role Model",
      "Rowing Speakers",
      "Rugby League",
      "Rugby Union",
      "Running",
      "Rural",
      "SAAS",
      "Safety",
      "Sailing",
      "Sales",
      "Saxton Engage",
      "Schizophrenia",
      "Science",
      "Security",
      "Self esteem",
      "Self Leadership",
      "Self- Mastery",
      "Self-Care",
      "Service Innovation",
      "Sex Education",
      "Sexual Assault Survivor",
      "Sexual Harassment",
      "Sexual Health",
      "Sexual Wellness",
      "Singers",
      "Slam Poet",
      "Sleep",
      "Small Business",
      "Smart Cities",
      "Snowboard",
      "Soccer",
      "Social Entrepreneur",
      "Social Inclusion",
      "Social Media",
      "Social Researcher",
      "Something Special",
      "Space",
      "Spirituality",
      "Sport",
      "Start Up",
      "STEAMM, STEM, STEMM",
      "Strategic Planning",
      "Strategy",
      "Stroke Survivor",
      "Succession Planning",
      "Suicide",
      "Superannuation",
      "Supply Chain",
      "Surfing",
      "Sustainability",
      "Swimming",
      "Swinburne Uni Alumi",
      "Sydney Uni Alumni",
      "Tax",
      "Taxation",
      "Te Reo",
      "Teacher",
      "Team building activities",
      "Teambuilding",
      "Teamwork",
      "Tech debt",
      "Tech entrepreneur",
      "Technology",
      "Television",
      "Tennis",
      "Time Management",
      "Torres Strait Islander",
      "Tourette's",
      "Tourism",
      "Toxic Masculinity",
      "Trade",
      "Tradesman",
      "Tradie",
      "Trainer",
      "Training",
      "Transformation",
      "Transgender",
      "Travel",
      "Trivia",
      "Trust",
      "Under 30",
      "User Experience (UX)",
      "Vaccination",
      "Vegan",
      "Veteran",
      "veterinarian",
      "Victoria Uni Alumni",
      "Video Games",
      "Virtual boardroom",
      "Virtual Comedy",
      "Virtual Reality",
      "Vision Impaired",
      "Visual Note-Taking",
      "Voice to Parliament",
      "Voice-over",
      "Volunteers",
      "Vulnerability",
      "Water",
      "Weather",
      "Web3",
      "Webinar",
      "Welcome to Country",
      "Wellbeing",
      "Wellness",
      "Wine",
      "Wine Tasting",
      "Winter Olympics & Paralympics",
      "Women in Business",
      "Women's Health",
      "Work-Life Balance",
      "Working From Home (WFH)",
      "Workplace accident",
      "Workplace Injury",
      "Workshop",
      "Yachting",
      "Yoga Teacher",
      "Young Leaders",
      "Zero Waste"
    ],
    expertisesOptions: [
      "Keynote Speakers",
      "MCs",
      "MCs >> Comedic",
      "In Conversation",
      "Workshops",
      "Entertainers >> Bands",
      "Entertainers >> Circus",
      "Entertainers >> Comedians",
      "Entertainers >> Corporate Team Building",
      "Entertainers >> Dancers",
      "Entertainers >> DJs",
      "Entertainers >> Drag Queens",
      "Entertainers >> Headline Acts",
      "Entertainers >> Impersonators",
      "Entertainers >> Interactive",
      "Entertainers >> Magicians, Mentalists & Illusionists",
      "Entertainers >> Musicians",
      "Entertainers >> Roving Performers",
      "Voice Over",
      "Ambassador",
      "Influencer"
    ],
    searchForm: {
      ...searchForm,
    },
    advancedFiltering: false,
    search: null,
    loadedSpeakerList: [],
    showLoadingScreen: false,
    loadingText: "Please Wait!",
  }),
  mounted() {},
  methods: {
    addIndustries (newTag) {    
       this.industriesOptions.push(newTag)
       this.searchForm.industries.push(newTag)
   }, 
    filterSpeakers() {
      this.loadingText = "Filtering Speakers";
      this.showLoadingScreen = true;
      this.searchForm.function_date = this.dealInfo.function_date;
      this.searchForm.speaker_list = this.speakerList.join(",");
      filterSpeakers(this.searchForm)
        .then((res) => {
          this.showLoadingScreen = false;
          this.loadedSpeakerList = res.data;
        })
        .catch((err) => console.log(err));
    },
    exclusivityPicked(option) {
      this.searchForm.exclusivity = option.id;
    },
    occupy(speaker) {
      this.loadingText = "Assigning Speaker...";
      this.showLoadingScreen = true;
      let data = {
        deal: this.dealInfo,
        speaker: speaker,
      };
      occupySpeakers(data)
        .then((res) => {
          this.showLoadingScreen = false;
          speaker.sp_exist = 1;
          this.$parent.fetchShortlistedSpeaker(this.dealInfo.deal_id);
        })
        .catch((err) => console.log(err));
    },
    noAssign(speaker) {
      if (
        confirm(
          "Are you sure you want to unassign the speaker? The associated shortlisted speaker will delete also."
        )
      ) {
        this.loadingText = "Unassigning Speaker...";
        this.showLoadingScreen = true;
        let data = {
          deal: this.dealInfo,
          speaker: speaker,
        };
        noAssignSpeaker(data)
          .then((res) => {
            this.showLoadingScreen = false;
            speaker.sp_exist = 0;
            this.$parent.fetchShortlistedSpeaker(this.dealInfo.deal_id);
          })
          .catch((err) => console.log(err));
      }
    },
    showConflict(speaker) {
      this.loadingText = "loading...";
      this.showLoadingScreen = true;
      this.selectedSpeaker = speaker;
      let data = {
        deal: this.dealInfo,
        speaker: speaker,
      };

      fetchConflictData(data)
        .then((res) => {
          this.showLoadingScreen = false;
          this.conflictSpeakerList = res.data;
          this.openConflictSP = true;

        })
        .catch((err) => console.log(err));
    },
    resetFilter(){
      this.searchForm = { ...searchForm };
      this.searchForm.industries = null;
      this.loadedSpeakerList = [];
    },
    closeModal() {
      this.searchForm = { ...searchForm };
      this.searchForm.industries = null;
      this.loadedSpeakerList = [];
      this.$emit("update:isVisible", false);
    },
  },
};
</script>

<style scoped>

  #filter-shortlisted-speakers-modal {
      z-index: 1000;
  }

</style>
